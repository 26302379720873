import axios from 'axios';

const api = axios.create({
    baseURL: "https://django-backend-604521917673.asia-northeast1.run.app",
    headers: {
        "X-Tenant-Domain": window.location.hostname, // Dynamically pass frontend domain
        "Content-Type": "application/json",
    },
});

// Interceptor for token refreshing
api.interceptors.response.use(
    response => response,
    async error => {
        if (error.response?.status === 401 && !error.config._retry) {
            error.config._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    const response = await api.post('/api/token/refresh/', { refresh: refreshToken });
                    localStorage.setItem('token', response.data.access);
                    api.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
                    error.config.headers['Authorization'] = `Bearer ${response.data.access}`;
                    return api(error.config);
                } catch (refreshError) {
                    console.error("Token refresh failed", refreshError);
                }
            }
        }
        return Promise.reject(error);
    }
);

export default api;
